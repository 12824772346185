import {
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Flex,
  Heading,
  Link,
  Text,
} from "@chakra-ui/react";
import { isMobile } from "react-device-detect";

export interface TestWelcomePageProps {
  onNextClicked: () => void;
}
function TestWelcomePage(props: TestWelcomePageProps) {
  return (
    <Flex
      flexGrow={1}
      justifyContent={"center"}
      margin={isMobile ? "1rem" : "0rem"}
    >
      <Card
        boxShadow={"none"}
        bg={"#35373f"}
        justifySelf={"center"}
        height={"50%"}
        marginTop={isMobile ? "0" : "4rem"}
        width={isMobile ? "100" : "70%"}
        borderLeft={"4px"}
        borderLeftColor={"#f56565"}
      >
        <CardHeader>
          <Box alignSelf="start">
            <Heading
              mt={"1rem"}
              color="#3acad3"
              textAlign={"center"}
              size={"lg"}
              fontWeight={"semibold"}
              style={{
                fontVariant: "small-caps",
              }}
            >
              Welcome to ETA Technologies!
            </Heading>
          </Box>
        </CardHeader>
        <CardBody>
          <Text
            marginTop={"1rem"}
            fontSize={isMobile ? "md" : "xl"}
            fontFamily={"sans-serif"}
            color="#bbbbbb"
            textAlign={"center"}
            whiteSpace={"pre-line"}
          >
            What Makes a Great Entrepreneur?{"\n\n"}ETA Technologies has crafted
            a cutting-edge psychometric test to measure and quantify your
            entrepreneurial persona.{"\n\n"}In just 10 minutes, you’ll complete
            an engaging assessment designed to uncover how entrepreneurial you
            truly are.{"\n\n"}Answer each question honestly and enjoy the
            process!{"\n\n"}If you have any questions or need assistance, please
            don’t hesitate to reach out to us at{" "}
            <Link href="mailto:info@eta-technologies.com" color={"teal.500"}>
              contact us
            </Link>
            .{"\n\n"}
            Best of luck!{"\n"}
            ETA Technologies Team
          </Text>
        </CardBody>
        <CardFooter justifyContent={"center"}>
          <Button
            justifyContent={"center"}
            padding={"0rem 2rem"}
            size={isMobile ? "md" : "lg"}
            fontSize={isMobile ? "md" : "lg"}
            onClick={props.onNextClicked}
          >
            Start the test
          </Button>
        </CardFooter>
      </Card>
    </Flex>
  );
}

export default TestWelcomePage;
